import xhr from './xhr/';
/**
 * 问题中心相关的 API
 */
class ProblemService {
	// 问题中心首页 获取后台传过来的select配置项
	getProblemSelect(data) {
		return xhr({
			method: 'get',
			url: '/problem/index',
			params: data,
		});
	}

	// 搜索
	getProblemList(data) {
		return xhr({
			method: 'get',
			url: '/problem/list',
			params: data,
		});
	}

	// 删除问题
	deleteProblem(data) {
		return xhr({
			method: 'post',
			url: '/problem/delete',
			params: data,
		});
	}

	// 分配问题负责人
	distribute(data) {
		return xhr({
			method: 'post',
			url: '/problem/distribution',
			params: data,
		});
	}

	// 分配问题负责人
	distributeAll(data) {
		return xhr({
			method: 'post',
			url: '/problem/distributionAll',
			params: data,
		});
	}

	// 获取问题详情
	getDetail(data) {
		return xhr({
			method: 'get',
			url: '/problem/detail',
			params: data,
		});
	}

	// 保存问题解决方案
	savaReslove(data) {
		return xhr({
			method: 'post',
			url: '/problem/modify',
			params: data,
		});
	}

	// 提交问题解决方案
	submitReslove(data) {
		return xhr({
			method: 'post',
			url: '/problem/submit',
			params: data,
		});
	}

	// 提交多个问题
	submitMore(data) {
		return xhr({
			method: 'post',
			url: '/problem/add_more',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new ProblemService();
